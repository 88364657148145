import { template as template_7d5d761bdac84c6392c924c935175199 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7d5d761bdac84c6392c924c935175199(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
