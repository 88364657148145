import { template as template_4327e4c9fa26459aaebd0c9bffc7bad7 } from "@ember/template-compiler";
const FKText = template_4327e4c9fa26459aaebd0c9bffc7bad7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
