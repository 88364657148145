import { template as template_5bca41aa47b14970822efb184daa5850 } from "@ember/template-compiler";
const WelcomeHeader = template_5bca41aa47b14970822efb184daa5850(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
