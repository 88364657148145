import { template as template_c6bac49838104ffb941db7cf2e956d8b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_c6bac49838104ffb941db7cf2e956d8b(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
