import { template as template_c7f45b8d14e74417b84e02e0ea7b3e72 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_c7f45b8d14e74417b84e02e0ea7b3e72(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
